import React, { useEffect, useRef } from 'react';
import { Splide } from '@splidejs/react-splide';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import RenderShopImages from '../../components/RenderShopImages';

const Shop = () => {
  const mainRef = useRef(null);
  const thumbsRef = useRef(null);

  useEffect(() => {
    if (mainRef.current && thumbsRef.current && thumbsRef.current.splide) {
      mainRef.current.sync(thumbsRef.current.splide);
    }
  }, []);

  return (
    <Layout>
      <div className='strip strip-blog strip-blog-page'>
        <div className='container'>
          <span className='section-title mb-3'>Dijual: Mouse Logitech</span>
          <div className='row my-3'>
            <div className='col-12 col-md-5 mb-4'>
              <div className='mb-1'>
                {typeof window !== 'undefined' && (
                  <Splide
                    options={{
                      arrows: false,
                      type: 'loop',
                      gap: '0.5rem',
                      pagination: false,
                    }}
                    ref={mainRef}
                  >
                    <RenderShopImages />
                  </Splide>
                )}
              </div>
              {typeof window !== 'undefined' && (
                <Splide
                  options={{
                    type: 'slide',
                    rewind: true,
                    gap: '0.5rem',
                    pagination: false,
                    fixedWidth: 110,
                    fixedHeight: 70,
                    cover: true,
                    focus: 'center',
                    isNavigation: true,
                  }}
                  ref={thumbsRef}
                >
                  <RenderShopImages />
                </Splide>
              )}
            </div>
            <div className='col-12 col-md-7'>
              <h3>
                Logitech MX Anywhere 3S Mouse Wireless Bluetooth Silent
                Rechargeable - Pale Grey/Putih
              </h3>
              <h1 className='pt-2 mb-0' style={{ color: '#F94D63' }}>
                Rp 950.000
              </h1>
              <span style={{ color: '#AAB4C8', fontSize: '14px' }}>
                <del>Rp 1.399.000</del>
              </span>
              <div className='py-3'>
                <a
                  href='https://wa.me/6281296566734'
                  className='button button-secondary'
                >
                  Beli Langsung
                </a>
              </div>
              <div>
                <p>
                  Kondisi barang baru, original Spanish, pembelian via Tokopedia
                  di tanggal 5 Januari 2024, alasan dijual karena{' '}
                  <strong>salah pilih warna</strong>.
                </p>
                <p>
                  Segel sudah terbuka untuk cek kelengkapan. Kelengkapan terdiri
                  dari mouse, kabel charger USB-C (USB-A ke USB-C) dan manual
                  book.
                </p>
                <p>
                  Cocok untuk video editor, graphic designer dan pekerja
                  profesional lainnya yang perlu presisi tinggi.{' '}
                  <strong>Gratis mouse pad.</strong>
                </p>
                <p>
                  Link pembelian di Tokopedia:
                  <br />
                  <a
                    href='https://tokopedia.link/7W7smlNObGb'
                    target='_blank'
                    rel='noreferrer'
                  >
                    https://tokopedia.link/7W7smlNObGb
                  </a>
                  <br />
                  <br />
                  Review di Youtube:
                  <br />
                  <a
                    href='https://www.youtube.com/watch?v=EThTEA4FDvU'
                    target='_blank'
                    rel='noreferrer'
                  >
                    https://www.youtube.com/watch?v=EThTEA4FDvU
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const Head = () => (
  <SEO title='Shop | Mouse Logitech MX Anywhere 3S'>
    <meta name='robots' content='noindex,nofollow' />
  </SEO>
);

export default Shop;
