import React from 'react';
import { withPrefix } from 'gatsby';
import { SplideSlide } from '@splidejs/react-splide';

const images = [
  {
    id: 'shop-1',
    src: '/images/shop/mouse-box-depan.jpg',
    alt: 'Box dari depan',
  },
  {
    id: 'shop-2',
    src: '/images/shop/mouse-box-belakang.jpg',
    alt: 'Box dari belakang',
  },
  {
    id: 'shop-3',
    src: '/images/shop/ss-tokopedia.jpg',
    alt: 'Screenshot tanggal beli dari Tokopedia',
  },
];

const RenderShopImages = () => (
  <>
    {images.map(({ id, src, alt }) => (
      <SplideSlide key={id}>
        <img src={withPrefix(src)} loading='lazy' width='100%' alt={alt} />
      </SplideSlide>
    ))}
  </>
);

export default RenderShopImages;
